import {
  ActionSocial,
  BlockChangeLanguage,
  ButtonChangeLanguage,
  ButtonLaunchApp,
  ButtonLighMode,
  HDLogo,
  HeaderAction,
  HeaderDashboardContainer,
  HeaderDashboardWrapper,
  HeaderLanguage,
  HeaderLanguageClose,
  HeaderLanguageContentTitle,
  HeaderLanguageTitle,
  ItemLanguage,
  ListLanguage,
} from "./styled";
import tele from "../../assets/Header/tele.png";
import teleLightmode from "../../assets/Header/tele_lightmode.png";
import teleHover from "../../assets/Header/tele_hover.png";
import header_logo from "../../assets/Header/dashboard_logo.png";
import header_logo_02 from "../../assets/Header/dashboard_logo_02.png";
import imgLightmode from "../../assets/Header/img_lightmode.png";
import imgLightmode02 from "../../assets/Header/img_lightmode_02.svg";
import twitter from "../../assets/Header/twitter.png";
import twitterLightmode from "../../assets/Header/twitter_lightmode.png";
import twitterHover from "../../assets/Header/twitter_hover.png";
import discord from "../../assets/Header/discord.png";
import discordLightmode from "../../assets/Header/discord_lightmode.png";
import discordHover from "../../assets/Header/discord_hover.png";
import icnClose from "../../assets/Common/icn_close.svg";
import icnCloseLight from "../../assets/Common/icn_close_light.svg";
import icnLanguage from "../../assets/Common/icn_language.svg";
import icnLanguageLight from "../../assets/Common/icn_language_light.svg";
import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import { ContextProviderWrapper } from "../Context";
import { Select } from "antd";
import { Trans, useTranslation } from "react-i18next";

const HeaderNew = () => {
  const { theme, toggleTheme, setLng, lng } = useContext(
    ContextProviderWrapper
  )!;
  const getLanguage = localStorage.getItem("i18nextLng");
  const [showModal, setShowModal] = useState(false);

  // show language
  const handleShowLanguage = () => {
    setShowModal(true);
  };
  const handleCloseLanguage = () => {
    setShowModal(false);
  };
  //translator
  const { i18n } = useTranslation();

  const changeLanguage = (lng: any) => {
    console.log(lng);
    i18n.changeLanguage(lng);
    setLng(lng);
    setShowModal(false);
    if (lng === "vi") {
      document.body.classList.add("is-vi");
    } else {
      document.body.classList.remove("is-vi");
    }
  };
  const handleChange = (event: any) => {
    setLng(event.target.value);
  };
  return (
    <HeaderDashboardContainer>
      <HeaderDashboardWrapper>
        <HDLogo to="https://kibble.exchange/">
          <img
            src={theme === "light" ? header_logo_02 : header_logo}
            alt="logo"
          />
        </HDLogo>
        <HeaderAction>
          <ButtonLighMode
            onClick={toggleTheme}
            className={theme === "light" ? "light" : ""}
          >
            <img className="sun" src={imgLightmode} alt="" />
            <img className="moon" src={imgLightmode02} alt="" />
          </ButtonLighMode>
          <HeaderLanguage>
            <ButtonChangeLanguage
              onClick={handleShowLanguage}
              className={theme}
            >
              <img
                src={theme === "light" ? icnLanguageLight : icnLanguage}
                alt=""
              />
            </ButtonChangeLanguage>
            <BlockChangeLanguage
              className={`${showModal ? "is-show" : ""} ${
                theme === "light" ? "light" : ""
              }`}
            >
              <HeaderLanguageContentTitle>
                <HeaderLanguageTitle>Select LAnGUAGE</HeaderLanguageTitle>
                <HeaderLanguageClose onClick={handleCloseLanguage}>
                  <img
                    src={theme === "light" ? icnCloseLight : icnClose}
                    alt=""
                  />
                </HeaderLanguageClose>
              </HeaderLanguageContentTitle>
              <ListLanguage onChange={handleChange}>
                {listLangs.map((item: any, index: number) => (
                  <ItemLanguage
                    className={getLanguage === item.value ? "is-active" : ""}
                    onClick={() => changeLanguage(item.value)}
                    key={index}
                  >
                    {item.text}
                    <span className={lng === "vi" ? "is-vi" : ""}>
                      {item.value}
                    </span>
                  </ItemLanguage>
                ))}
              </ListLanguage>
            </BlockChangeLanguage>
          </HeaderLanguage>
          <ActionSocial className={theme === "light" ? "light" : ""}>
            {socialAction.map((item, index) => {
              return (
                <li key={index}>
                  <Link to={item.href} target="_blank">
                    <img
                      className="image-normal"
                      src={theme === "light" ? item.iconLightmode : item.icon}
                      alt="icon"
                    />
                    <img
                      className="image-hover"
                      src={item.icnHover}
                      alt="icon"
                    />
                  </Link>
                </li>
              );
            })}
          </ActionSocial>
        </HeaderAction>
        <ButtonLaunchApp
          href="https://app.kibble.exchange/"
          className={theme === "light" ? "light" : ""}
        >
          <p className={lng === "vi" ? "is-vi" : ""}>
            <Trans>header.LAUNCH APP</Trans>
          </p>
        </ButtonLaunchApp>
      </HeaderDashboardWrapper>
    </HeaderDashboardContainer>
  );
};

const socialAction = [
  {
    icon: tele,
    iconLightmode: teleLightmode,
    icnHover: teleHover,
    href: "https://t.me/KibbleChat",
  },
  {
    icon: twitter,
    iconLightmode: twitterLightmode,
    icnHover: twitterHover,
    href: "https://twitter.com/KibbleExchange",
  },
  {
    icon: discord,
    iconLightmode: discordLightmode,
    icnHover: discordHover,
    href: "https://discord.gg/kibble",
  },
];

const listLangs = [
  {
    text: "English",
    value: "en",
  },
  {
    text: "China",
    value: "cn",
  },
  {
    text: "اَلْعَرَبِيَّةُ",
    value: "ar",
  },
  {
    text: "Deutsch",
    value: "de",
  },
  {
    text: "Français",
    value: "fr",
  },
  {
    text: "Русский",
    value: "ru",
  },
  {
    text: "Tiếng Việt",
    value: "vi",
  },
  {
    text: "Brasil",
    value: "pt",
  },
];

export default HeaderNew;
